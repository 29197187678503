<template>
  <show-more-block
    :show-more="showMore"
    :section-class="blockClass"
    :update-list="updateMedia"
    :sort="sort"
    :page="page"
    :sort-type="sortType"
    :init-size="media.length"
  >
    <video-tiles :tiles="media" :use-favourite-remove="useFavouriteRemove" />
  </show-more-block>
</template>

<script>
import VideoTiles from "@/components/page/video/VideoTiles";
import ShowMoreBlock from "@/components/page/items/list/ShowMoreBlock";
export default {
  name: "MediaViewsBlock",
  components: { ShowMoreBlock, VideoTiles },
  props: {
    blockClass: String,
    media: Array,
    showMore: {
      type: Boolean,
      default: true
    },
    sort: {
      type: String,
      default: ""
    },
    sortType: {
      type: String,
      default: ""
    },
    updateMedia: Function,
    page: {
      type: Number,
      default: 1
    },
    useFavouriteRemove: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped></style>
